// Import (~ is used for things in nodes_modules)
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

@font-face{
  font-family : 'Museo100';
  src : url('fonts/Museo100.otf') format('truetype');
}

@font-face{
  font-family : 'Museo300';
  src : url('fonts/Museo300-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Museo500';
  src: url('fonts/Museo500.otf') format('truetype');
}

@font-face{
  font-family : 'Museo700';
  src : url('fonts/Museo700-Regular.otf') format('truetype');
}

@font-face{
  font-family : 'Museo900';
  src : url('fonts/Museo900.otf') format('truetype');
}

.cardBox {
  width: 100%;
  height: 100%;
  float: left;
  font-size: 1.2em;
  perspective: 800px;
  transition: all 0.4s ease 0s;
  padding-top: 10px;
}
.cardBox:hover .card {
  transform: rotateY(180deg);
}
.card {
  position: relative;
  cursor: default !important;
  transform-style: preserve-3d;
  transition: transform 0.5s ease 0s;
  background-color: rgba( 255,255,255, 0.34);
}
.card p {
  margin-bottom: 1.8em;
}
.card .back {
  top: 20px;
  backface-visibility: hidden;
  box-sizing: border-box;
  color: black;
  display: block;
  font-size: 1.2em;
  padding: 0.8em 0.7em;
  position: absolute;
  text-align: center;
}

.card .front {
  background-color: transparent;
  top: 0;
  object-fit: fill;
  backface-visibility: hidden;
  box-sizing: border-box;
  color: black;
  display: block;
  font-size: 1.2em;
  padding: 0.8em 0.7em;
  position: relative;
  text-align: center;
}

.card .back {
  transform: rotateY( 180deg);
}
.card .back a {
  padding: 0.3em 0.5em;
  background: #333;
  color: black;
  text-decoration: none;
  border-radius: 1px;
  font-size: 0.9em;
  transition: all 0.2s ease 0s;
}
.card .back a:hover {
  background: black;
  color: #333;
  text-shadow: 0 0 1px #333;
}
.cardBox:nth-child(1) .card .back {
  background: grey;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 767px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 46%;
  }
  .card {
    height: fit-content !important;
//    height: 285px;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 480px) {
  .cardBox {
    width: 94.5%;
  }
  .card {
    height: fit-content !important;
//    height: 260px;
  }
  // Customize Bootstrap variables

  //.test {
  //  color: $indigo;
  //}
}
.gray-dark {
  color: $gray-700;
}
.flippingCardBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  perspective: 1000px;
}

.flippingCardBox .flippingCard {
  position: relative;
  transform-style: preserve-3d;
  transition: 1s ease;
}

.flippingCardBox .flippingCard .flippingCard-front,
.flippingCardBox .flippingCard .flippingCard-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flippingCardBox .flippingCard .flippingCard-front {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #262626;
  color: #fff;
}

.flippingCardBox .flippingCard .flippingCard-front .flippingCard-content {
  text-align: center;
}

.flippingCardBox .flippingCard .flippingCard-back {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #fff;
  color: #262626;
}

.flippingCardBox:hover .flippingCard {
  transform: rotateY(180deg);
}

.flippingCardBox .flippingCard .flippingCard-back {
  transform: rotateY(180deg);
}