/* Layout Properties */

/* Divs */

.navbar {
    top: 0px;
    left: 0px;
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000029;
    opacity: 1;
}